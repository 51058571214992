// Required dependencies
import 'jquery';
// import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


import netteForms from 'nette-forms';

window.Nette = netteForms;
// netteForms.showModal = function() {};

netteForms.initOnLoad();

// Assets
// These assets are extracted to [id].bundle.css using MiniCssExtractPlugin, see more on webpack.config.js
// import 'bootstrap/dist/css/bootstrap.css';
// import './theme/front.scss';
// import './theme/admin.scss';

// UI tweaks
// import './ui/naja';

// console.log(jQuery().jquery);

console.log('Test');

$(document).ready(function () {
	console.log('jQuery ready');
});

window['grecaptchaInit'] = function () {
	$('.g-recaptcha').each(function () {
		var recaptcha = $(this);

		if (recaptcha.children().length) { // already processed -> skip
			return;
		}

		grecaptcha.render(recaptcha[0], {
			sitekey: recaptcha.attr('data-sitekey')
		});
	});
}

// Bootstrap Tooltips
/*
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {
	html: true
}))
*/
